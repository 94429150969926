import { render, staticRenderFns } from "./CatalogProductList.vue?vue&type=template&id=67019e80&scoped=true&"
import script from "./CatalogProductList.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CatalogProductList.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CatalogProductList.vue?vue&type=style&index=0&id=67019e80&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67019e80",
  null
  
)

export default component.exports