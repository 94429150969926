import { render, staticRenderFns } from "./FireTable.vue?vue&type=template&id=30453f88&scoped=true&"
import script from "./FireTable.vue?vue&type=script&setup=true&lang=ts&"
export * from "./FireTable.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./FireTable.vue?vue&type=style&index=0&id=30453f88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30453f88",
  null
  
)

export default component.exports